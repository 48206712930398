<template>
  <div class="multi-chart relative" :class="getTheme ? 'dark' : ''">
   
    <div
      v-if="isloading"
      class="absolute backdrop-blur-sm w-full h-[100vh] flex items-center justify-center"
      style="z-index: 9999"
    >
      <Loader></Loader>
    </div>

            <div
                  v-if="openIndicatorSetting"
                  class="absolute flex items-center justify-center -bg-red-200 w-full h-[100vh]"
                  style="z-index: 999"
                >
                  <IndicatorSettingModal @close="handleSettingModalClose" @settingChanged="handleSettingChanged"/>
            </div>


    <span>
      <div
        :width="cbox_width"
       :height = "cbox_height"
        class="space-y-1 bg-[#e0e3eb] overflow-hidden dark:bg-[#373738]"
      >
        <div
          v-if="showNavRight"
          class="w-full md:h-[6vh] h-[4vh] "
          :class="getTheme ? 'bg-[#1a1a1a]' : 'bg-white'"
        >
          <Navbar
            @getTimeStamp="onHandleTimeStamp"
            @refresh="onHandleRefresh"
            @getSymbolData="onHandleSymbolData"
            @timeFrameEmit="onHandleTimeFrame"
            @onSubHandler="onSubHandler"
            @addIndicator="addIndicator"
            @onMagnetPress="magToggle"
            @updateTheme="updateTheme"
            @onPressResistance="handleResistanceLine"
            :isMagnet="magnet"
            :isResistance="showResistanceLine"
            :variants="variants"
          ></Navbar>
        </div>
        <div class="w-full flex space-x-1 " :class="showNavRight?'h-[94vh] -bg-green-600':'h-[100vh]'">
          <div
            v-if="showNavRight"
            class="h-full"
            :class="[getTheme ? 'bg-[#1a1a1a]' : 'bg-white',width < 900?'w-[6%] md:w-[10%]':'w-[2.5%]']"
          >
            <Leftbar  :isMagnet="magnet"></Leftbar>
          </div>
          <div class="h-full" :class="showNavRight?`${width < 900 ? 'w-[92%] md:w-[88%] -bg-red-400':'w-[96.5%]'}`:'w-[100%]'">
            <div
              v-if="errorMessage"
              class="w-full h-[80vh] dark:text-white text-[16px] font-medium flex items-center justify-center"
            >
              Failed to fetch chart data.
              <span
                @click="updateSymbol(symbolName)"
                class="hover:underline cursor-pointer text-green-700"
              >
                Please try again later.</span
              >
            </div>
            <div v-else style="position: relative" ref="tradingHw">
              
              <trading-vue
                v-if="!isloading"
                id="newData"
                ref="tvjs"
                :applyShaders="true"
                :enableZoom="true"
                :timezone="0"
                :index-based="true"
                :data="chart2"
                :titleTxt="stockTitle"
                :overlays="overlays"
                :width="cbox_width"
                :height="cbox_height"
                :color-back="colors.colorBack"
                :color-grid="colors.colorGrid"
                :color-text="colors.colorText"
                :colorVolUp="candleVolumeUpD"
                :colorVolDw="candleVolumeDwD"
                :colorCandleUp="candleColorUpD"
                :colorCandleDw="candleColorDwD"
                :gridLines="true"
                :colorTitle="titleColor"
                :enableArrow="false"
                :enableCrosshair="enableCrosshair"
                :waterMarkText="waterMarkText"
                :magnet="magnet"
                @darkMode="darkMode"
                :colorGrid="isDark ? '#292929' : `${variants?.sixthVariant ? '#686868':'#efefef'}`"
                :showGridYLine = "variants?.sixthVariant"
                :isDark="isDark"
                :colorCross="colors.colorCross"
                :candleBorder="candleBorder"
                :firstVariant="variants.firstVariant"
                :secondVariant="variants.secondVariant"
                :thirdVariant="variants.thirdVariant"
                :fourthVariant="variants.fourthVariant"
                :fifthVariant="variants.fifthVariant"
                :sixthVariant="variants.sixthVariant"
                :colorPanel="'blue'"
                @showMagnetOnChart="toggleMagnet"
              >
              </trading-vue>


              
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import TradingVue, {DataCube} from './@trading-vue-js/src/index'
import data from './data_aapl.json'
import moment from 'moment'
import Navbar from './component/Navbar.vue'
import ChartEventNew, {GetTimeRange} from './utils/chartEvent.js'
import {themeList} from './utils/chartSetting.js'
import IndicatorSettingModal from './component/IndicatorSettingModal.vue'
import {mapActions, mapGetters} from 'vuex'
import Loader from './component/Loading.vue'
import Leftbar from './component/Leftbar.vue'
import Overlays from './overlays'
import { namespaces } from 'd3'

const serverUrl = 'https://core.incometrader.com'

let chartEvents = new ChartEventNew()

export function parseToChartCandleData(data) {
 
  return data
}

export default {
  name: 'SMEChart',
  description: 'All charts must work correctly and independently',
  components: {
    TradingVue,
    Navbar,
    Loader,
    Leftbar,
    IndicatorSettingModal
  },
  data() {
    return {
      showResistanceLine:false,
      showFirstTimeResistanceLine:false,
      customIndicator:[
      {
      id: 21,
      name: "CovalData",
      chartType:'offchart',
      desc: "COVAL TREND INDICATOR",
      settings: {
        // grid: {
        borderColor: '#111',
        strokeDashArray: 7,
        lineWidth: 0.7,
        // },
      }
  },
  {
    id: 7,
    name: "MACD",
    desc: "Moving Average Convergence/Divergence",
    chartType:'offchart',
    type:'MACD',
    data:[],
    settings: {
      macdColor: '#3782f2',
      signalColor: '#f48709',
      histColor: '#C0C0C0',
        histWidth: 4,
        macdWidth: 1,
        signalWidth: 1,
        crosses: [],
      },
}
      ],
      chartSetting: [],
      errorMessage: false,
      showNavRight: true,
      firstVariant: false,
      secondVariant: false,
      thirdVariant: false,
      fourthVariant: false,
      variants: {
        firstVariant: true,
        secondVariant: false,
        thirdVariant: false,
        fourthVariant: false,
        fifthVariant: false,
        sixthVariant: false,
      },
      waterMarkText: 'incometradercharts.com',
      enableCrosshair: true,
      candleBorder: true,
      magnet: true,
      isDark: false,
      volume: null,
      addOffChart: '',
      offChartList: [],
      onChartList: [],
      tempResponse: null,
      stockTitle: 'New Chart',
      search: '',
      isOld: false,
      flagsData: '',
      excode: 'NSD',
      excodeList: [],
      date: '2023-09-07',
      charts: new DataCube(data),
      flagList: [],
      supportLines: [],
      overlays: Object.values(Overlays),
      width: window.innerWidth,
      height: window.innerHeight,
     
      colorsVolume: {
        colorUpVol: '#16B195',
        colorUpDow: '#F23645',
      },
      timeFrame: 'M',
      isloading: false,
      colorTitle: {
        title: 'green',
      },
      myWidth: null,
      dropdownOpen: false,
      supportData:[],
      resistanceData:[],
      srVal: true,
      rangeData: [
        { key: '1', value: '1M' },
        { key: '3', value: '3M' },
        { key: '6', value: '6M' },
        { key: 'ytd', value: 'YTD' },
        { key: '12', value: '1Y' },
        { key: '24', value: '2Y' },
        { key: '60', value: '5Y' },
        { key: this.currDate, value: 'Max' },
      ],
      selectedDate: '1',
      selectRangeVal : '1Y',
      rangeDate: null,
      currDate: moment().format('YYYY-MM-DD'),
      candleData: [],
      symbolName: 'IBM',
      candleColorUpD: '#008001',
      candleColorDwD: '#800000',
      candleVolumeUpD: '#008001',
      candleVolumeDwD: '#008001',
      openIndicatorSetting:false,
      isPageReload: false,
      currentSetting:{},
    }
  },

  methods: {
    ...mapActions(['updateOhlcData', 'updateSymbolName','toggleIndicator','updateIndicatorList','updateRemoveIndicatorName','selectedVariant']),
    onHandleTimeFrame(t) {
      this.timeFrame = t
      this.updateSymbol(this.symbolName)
    },
    onSubHandler(payload) {
      this.chartSetting = payload
      this.candleColorUpD = this.getValue('colorCandleUp')
      this.candleColorDwD = this.getValue('colorCandleDw')
      this.candleVolumeUpD = this.getValue('colorVolUp')
      this.candleVolumeDwD = this.getValue('colorVolDw')
      this.enableCrosshair = this.getValue('enableCrosshair')
      this.candleBorder = this.getValue('candleBorder')
      this.variants = this.getSelectedVariant


    },
    updateTheme(col){
      this.candleColorUpD = col?.colorCandleUp
      this.candleColorDwD = col?.colorCandleDw
      this.candleVolumeUpD = col?.colorVolUp
      this.candleVolumeDwD = col?.colorVolDw
      this.variants = this.getSelectedVariant
    },
    updateThemeFromUrl(val){
      if(val){
        let obj = themeList?.find(item=>item.id == val)
        if(obj?.id){
          this.selectedVariant(obj.key)
          this.updateTheme(obj.colors)
        }
      }
    },
    getValue(name) {
      if (name !== 'Variant') {
        const setting = this.chartSetting?.find(
          (item) => item?.name === name && item?.name !== 'Variant'
        )
        return setting ? setting.defaultValue : null
      } else {
        const setting = this.chartSetting?.find((item) => item?.name === name)
        return setting.variantList || null
      }
    },
    onHandleSymbolData(payload) {
      if (payload){
        localStorage.setItem('symbolName', payload)
        this.symbolName = payload
        this.updateSymbolName(payload)
        this.updateSymbol(payload)
      }
    },
    onHandleRefresh() {
      let range = this.$refs.tvjs.getRange()
      console.log("range",range)
      this.$refs.tvjs.goto(1686661705)
      // this.$refs.tvjs.resetChart()
    },
    onHandleTimeStamp(payload) {
      this.selectRangeVal = payload
      let [startingTime, endingTime] = GetTimeRange(this.candleData,payload,this.timeFrame)
      this.$refs.tvjs?.setRange(startingTime, endingTime)
    },
    getFullDate(month) {
      const date = moment()
      const selectedMonth = parseInt(month)
      const getDate = date.subtract(selectedMonth, 'months')
      const result = getDate.format('YYYY-MM-DD')
      return result
    },
    getRangeData() {
      this.rangeDate = this.getFullDate(this.selectedDate)
      this.updateSymbol(this.symbolName)
    },
    modeToggle() {
      this.isDark = !this.isDark
    },
    oldDataToggle(event) {
      this.isOld = event.target.checked
      this.updateSymbol(this.symbolName)
    },
    dropFunc() {
      const opt = document.querySelector('.dropdown').value
    },
    magToggle() {
      this.magnet = !this.magnet
    },
    srToggle(event) {
      this.srVal = event.target.checked
      this.updateSymbol(this.symbolName)
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    toggleVariant(variant) {
      this.firstVariant = false
      this.secondVariant = false
      this.thirdVariant = false
      this.fourthVariant = false

     
    },
    darkMode() {
      this.isDark = !this.isDark
    },
    searchData() {
      this.updateSymbol(this.search)
    },
    toggleMagnet() {
      this.magnet = !this.magnet
    },
    macdIndicator(){
      const data = this.tempResponse?.data;
      const macd = data?.macd;

        let signalLine = macd && macdData?.signalLine
        let histogram = macd?.histogram
        let macdLine = macd?.macdLine
        let crossOverMACD = macd?.crossOverMACD

        let finalOutput = []
        for (let i = 0; i < histogram?.length; i++) {
          const timeStamp = histogram[i][0]
          const flag = crossOverMACD?.find((d) => d[0] === timeStamp)
          const flagValue = flag ? flag[1] : 0

          finalOutput.push([
            timeStamp,
            histogram[i][1],
            macdLine[i][1],
            signalLine[i][1],
            flagValue,
          ])
        }

        return {finalOutput, crossOverMACD}
    },
    covalIndicator(){
      let data = this.tempResponse?.data
        const covalData = data?.covalData
        const crossOverFill = data?.crossOverFill

        const covalDataMain =
        covalData && covalData.length > 0 ? parseToChartCandleData(covalData[0]) : []
        const crossOverFillMain = parseToChartCandleData(crossOverFill)

        let covalDataArray = []

        for (let i = 0; i < covalDataMain.length; i++) {
          const timeStamp = covalDataMain[i][0]
          const flag = crossOverFillMain.find((d) => d[0] === timeStamp)
          const flagValue = flag ? flag[1] : 0

          covalDataArray.push([...covalDataMain[i], flagValue])
        }

        return { covalDataMain, covalDataArray }
    },
    addIndicator(indicator) {
      if(this.variants?.sixthVariant && indicator?.chartType == 'offchart'){
        let dataRes = this.charts?.data?.offchart?.filter(item=>item?.name != 'Coval Indicator' && item?.name != 'Volume')
        let isPresent = dataRes.some(item=> item?.type == indicator?.type || item?.name == indicator?.name || item?.type == indicator?.name)
        console.log("isPresent==>",isPresent,dataRes,indicator)
        if(isPresent){
          for (const data of dataRes) {
            this.charts.del(data?.name)
          }
          return
        } else{
          for (const data of dataRes) {
            this.charts.del(data?.name)
          }
        }
      }

      let type = indicator?.name;
      let proto = this.overlays?.find((x) => x.name === type);
      console.log("{proto}==>",{proto})
      if (proto && proto?.methods?.meta_info) {
        let meta = proto?.methods?.meta_info();
        let side = indicator?.name === "CovalData" ? 'offchart' : meta.preset?.side || 'onchart'
        const query = `${side}.${type}`;
        let chartData = this.charts?.get(query);

        for (const data of chartData) {
          this.charts.del(data?.name)
          return
        }

        let oveylay_tv;
        const {finalOutput, crossOverMACD} =  this.addMacdIndicator()
        const {covalDataMain,covalDataArray} = this.covalIndicator()


        if(indicator.name === 'MACD') {
          oveylay_tv = {
            type,
            name: meta?.preset?.name,
            desc: meta?.desc,
            settings: {
            macdColor: '#8D201D',
            signalColor: '#101089',
            histColor: '#C0C0C0',
            histWidth: 4,
            macdWidth: 1,
            signalWidth: 1,
            crosses: parseToChartCandleData(crossOverMACD)},
            tf:"1D",
            settingsLabelMap: meta.preset?.settingsLabelMap ? meta.preset?.settingsLabelMap : {},
            side,
            data: parseToChartCandleData(finalOutput)
          }

        }else if(indicator.name === "CovalData"){
          oveylay_tv = {
            type,
            name: meta?.preset?.name,
            desc: meta?.desc,
            settings: {
             
                firstLine: "#101089",
                secondLine: "#8D201D",
                lineWidth: 0.7,
            crosses: parseToChartCandleData(covalDataArray)},
            tf:"1D",
            settingsLabelMap: meta?.preset?.settingsLabelMap ? meta?.preset?.settingsLabelMap : {},
            side,
            data: parseToChartCandleData(covalDataMain)
          }

        }
        else{
          oveylay_tv = {
          type,
          name: meta?.preset?.name,
          desc: meta?.desc,
          settings: meta.preset.settings,
          tf:"1D",
          settingsLabelMap: meta?.preset?.settingsLabelMap ? meta.preset.settingsLabelMap : {},
          side,
          data: []
        };
        }

        let vol;
        if(side == 'offchart'){
          vol = this.charts.data.offchart.find((item)=>item?.name =='Volume')
          this.charts.data.offchart = this.charts.data.offchart.filter((item)=>item?.name != 'Volume')
        } 

        let id = this.charts.add(side, oveylay_tv)
        if(side == 'offchart'){
         let res = this.charts?.add(side,vol)
        }
      }
    },
    addCovalIndicator() {
      const newKeyValues = new URLSearchParams(window.location.search)
      const lineChart = newKeyValues.get('coval')
      if (lineChart !== 'false') {
        let dataTemp = this.tempResponse?.data
        const covalData = dataTemp?.covalData
        const crossOverFill = dataTemp?.crossOverFill

        const covalDataMain =
          covalData && covalData.length > 0
            ? parseToChartCandleData(covalData[0])
            : []
        const crossOverFillMain = parseToChartCandleData(crossOverFill)

        let covalDataArray = []

        for (let i = 0; i < covalDataMain.length; i++) {
          const timeStamp = covalDataMain[i][0]
          const flag = crossOverFillMain.find((d) => d[0] === timeStamp)
          const flagValue = flag ? flag[1] : 0

          covalDataArray.push([...covalDataMain[i], flagValue])
        }

        return { covalDataMain, covalDataArray }

       
      }
    },
     addMacdIndicator() {
      const keyword = window.location.search
      const newKeyValues = new URLSearchParams(keyword)
      let macdChart = newKeyValues.get('macd')

      if (macdChart !== 'false') {
        let dataTemp =  this.tempResponse?.data
        const macdData = dataTemp?.macd

        let signalLine = macdData && macdData?.signalLine
        let histogram = macdData?.histogram
        let macdLine = macdData?.macdLine
        let crossOverMACD = macdData?.crossOverMACD

        let finalOutput = []
        for (let i = 0; i < histogram?.length; i++) {
          const timeStamp = histogram[i][0]
          const flag = crossOverMACD?.find((d) => d[0] === timeStamp)
          const flagValue = flag ? flag[1] : 0

          finalOutput.push([
            timeStamp,
            histogram[i][1],
            macdLine[i][1],
            signalLine[i][1],
            flagValue,
          ])
        }

        return {finalOutput,crossOverMACD}

      
      } 
    
    },
    handleResistanceLine(){
      this.showResistanceLine = !this.showResistanceLine
      if(this.showResistanceLine){
        this.addResistanceLine()
      }else{
        this.removeResistanceLine()
      }
    },
    getSupportLines(data, type = 'Tradeline', settings = {}) {
        return {
          name: 'support',
          type: type,
          indexBased: false,
          data: data,
          settings: settings,
        }
      },
    addResistanceLine(){
    
      let data = this.tempResponse?.data
      this.supportData = data?.supportData
      this.resistanceData = data?.resistanceData
      let supportLineSettings = {
        'z-index': 5,
        legend: false,
      }
        if (this.supportData) {
          let overlay = this.getSupportLines(this.supportData, 'Tradeline', {
            ...supportLineSettings,
            color: 'red'
          })
          this.charts.add('onchart',overlay)
        }
     
        if (this.resistanceData) {
          let overlay = this.getSupportLines(this.resistanceData, 'Tradeline', {
            ...supportLineSettings,
            color: 'green'
          })
          this.charts.add('onchart',overlay)
        }

    
    },
    removeResistanceLine(){
      this.charts.del('support')
    },
    onResize(event) {
      const Height_widht = window.location.search
      const new_Height_widht = new URLSearchParams(Height_widht)
      const my_width = new_Height_widht.get('width')
      const my_height = new_Height_widht.get('height')
      this.width = my_width ? my_width : window.innerWidth
      this.height = my_height ? my_height : window.innerHeight - 42
    },
    getChartResponse: function (res, dataProp) {
      let data = res[dataProp]
      let candleParse = parseToChartCandleData(data?.ohlcv)
      this.updateOhlcData(candleParse)
      this.candleData = candleParse
 
      let charts = {
        ohlcv: candleParse,
        type: this.getChartType,
        tf: this.timeFrame,
      }
     
      let covalData = data?.covalData
      let macdData = data?.macd
      let crossOverFill = data?.crossOverFill
      let ohlcArrow = data?.ohlcArrow
      let SMA = data?.sma
      this.ohlcv = candleParse

      let ArrowData = {
        name: 'Arrow',
        type: 'ArrowData',
        indexBased: false,
        data: [[1691460000000, 135.5, true]],
        settings: {
          'z-index': 5,
        },
      }

    this.offChartList = this.offChartList.filter((i)=> i.name !== "Volume");

      this.offChartList.push({
          name: 'Volume',
          type: 'VolumePrimary',
          data: parseToChartCandleData(data?.ohlcv),
          settings: {
            legend: true,
            grid: {
              borderColor: '#111',
              strokeDashArray: 7,
            },
          },
        })

      if (this.isPageReload) {
        this.isPageReload = false
       
        this.ohlcv = ohlcArrow
        this.onChartList.push({
          name: 'Arrow',
          type: 'ArrowData',
          indexBased: true,
          data: parseToChartCandleData(ohlcArrow),
          settings: {
            'z-index': 0,
            legend: false,
          },
          showLabel: true,
        })
      }

      const keyword = window.location.search
     
     
      charts['onchart'] = this.onChartList 
      charts['offchart'] = this.offChartList
      return charts
    },
    deleteAllOnchartList() {
      let oncharts = this.charts.get('onchart')
      let updatedArr = oncharts?.filter((item) => (item.name != 'Arrow' && item.name != 'support') )
      updatedArr?.map((item) => this.charts.del(item.id))
    },

    updateStoreIndicatorFromStorage(){
      let x = localStorage.getItem("indicatorsList")
      let parseData = JSON.parse(x)
      if(parseData?.length > 0){
       this.updateIndicatorList(parseData)
      }
    },

    updateSymbol(name) {
      this.isloading = true
      this.errorMessage = false
      this.deleteAllOnchartList()

      let data = serverUrl + '/api/Data/WeeklyCandleData?startWith=' + name + '&end=' + '&excode=' + this.excode
      let oldData = serverUrl + '/api/Flag/compareNew?sym=' + name + '&isOld=true'
      fetch(this.isOld ? oldData : data)
        .then(async (d) => {
          let response = await d.json()
          this.volume = response?.data?.ohlcv[response?.data?.ohlcv?.length - 1]
          
          if (response) {
            this.flagsData = response?.flagsData
            this.stockTitle = response?.symbol
            this.tempResponse = response
            let newData = this.getChartResponse(response, 'data')
            let dataCube = new DataCube({ ...newData })

           

            if(this.showFirstTimeResistanceLine && response?.data?.resistanceData?.length > 0 && response?.data?.supportData?.length > 0){
                this.showFirstTimeResistanceLine = false   
                setTimeout(()=>{
                    if(this.charts){
                        this.addResistanceLine()
                      }
                    })
                  }
                  
                  this.charts = dataCube

                  setTimeout(() => {
                    if(newData?.ohlcv?.length > 0){
                      this.onHandleTimeStamp(this.selectRangeVal)
                    }
                    
                  })

                  const sr = new URLSearchParams(window.location.search)
                  const srValue = sr.get('sr')

                  if(srValue=='tr'){
                    this.showResistanceLine = true
                    setTimeout(()=>{
                      this.addResistanceLine()
                    })
                  }else if(srValue=='fal'){
                    this.showResistanceLine = false
                    setTimeout(()=>{
                      this.removeResistanceLine()
                    })

                  }
            

                  
                  
                  
                  setTimeout(() => {
                    if(this.charts && this.getSelectedIndicators && this.getSelectedIndicators.length > 0){
                    if(this.getSelectedIndicators){
                      this.getSelectedIndicators.map((item)=>{
                        this.addIndicator(item)
                      })
                   
                    }
                  }
                  });
          }
          
        })
        .catch((e) => {
          this.isloading = false
          this.errorMessage = true
        })
        .finally(() => {
          this.isloading = false
        })
    },
    handleRemoveIndicator(name){
        let obj = {name:name}
        this.charts.del(name)
        this.toggleIndicator(obj);

        this.updateRemoveIndicatorName(null)

   
    },
    handleSettingModalClose(){
      this.openIndicatorSetting = false
    },
    handleSettingChanged(val){
      const name = this.getCurrentChartSetting?.id?.replace("_0",'')
      const side = this.getSelectedIndicators?.find((item)=>item.name === name)
      if(this.getSelectedIndicators.length > 0 && side){
      let query = `${side.chartType}.${name}.settings`;
      let key1 = val.x
      let value = val.y
      const setObj = {key1: value}
      this.charts.merge(query,setObj);
    }
    },
    updateSetting(set){
      this.openIndicatorSetting = true
   
    }
  },
  computed: {
    ...mapGetters(['getTheme', 'getChartType', 'getSelectedIndicators','getRemoveIndicatorName','getSelectedVariant','getCurrentChartSetting']),

    titleColor() {
      return this.isDark ? '#fff' : '#000'
    },

    colors() {
      if (!this.isDark) {
        return {
          colorBack: '#fff',
          colorGrid: '#e0dcdc',
          colorText: '#333',
          colorWickUp: '#000000',
          colorWickDw: '#000000',
          colorCross: '#8091a0',
        }
      } else {
        return {
          colorBack: '#1A1A1A',
          colorGrid: '#3B3B3B',
          colorText: '#6B6B6B',
          colorWickUp: '#188874',
          colorWickDw: '#B21523',
          colorCross: '#6B6B6B',
        }
      }
    },
    computedOffChartList() {
      return this.addOffChart
    },
    chart2() {
      let chartType = this.getChartType
      if (
        this.charts?.data?.chart?.type &&
        this.charts?.data?.chart?.type !== chartType
      ) {
        this.charts.data.chart.type = chartType
      }
      return this.charts
    },
    iframStyle() {
      return { width: this.cbox_width }
    },
     cbox_width() {
      if (this.showNavRight) {
        if(this.width < 900){
          return Math.floor(this.width - 30)
        }else{
          return Math.floor(this.width - 50)
        }
      } else {
        return Math.floor(this.width)
      }
    },
    cbox_height() {
      if (this.showNavRight) {
        if(this.height > 800){
          return Math.floor(this.height - 15)
        }
        else if(this.height > 700){
          return Math.floor(this.height)
        }else{
          return Math.floor(this.height+5)

        }
      } else {
        return Math.floor(this.height + 40)
      }
    },
   
  },
  created() {
  
    this.rangeDate = this.getFullDate(this.selectedDate)
    
  },
  mounted() {
    this.updateStoreIndicatorFromStorage()
    let symbolStorage = localStorage.getItem('symbolName')
    if(symbolStorage){
      this.symbolName = symbolStorage
    }else{
       this.symbolName = 'IBM'
    }


    window.addEventListener('resize', this.onResize)
    this.$store.commit('SET_TRADING_CHART_REFERENCE', {
      tradingChartReference: this.$refs.tvjs,
    })
    this.onResize()
    

  

    fetch(
      serverUrl +
        '/api/Admin/GetActiveExchangeListNamesByCode' +
        '?date=' +
        this.date
    ).then(async (d) => {
      let response = await d?.json()
      this.excodeList = response
    })

 
    this.isPageReload = false
    this.updateSymbol(this.symbolName)
   

    const params = new URLSearchParams(window.location.search)
    const variantParam = params.get('theme')
   
    const navParam = params.get('showTool')

     if (variantParam) {
      this.updateThemeFromUrl(variantParam)
     }

    if (navParam) {
      if (navParam == 'true') {
        // this.showNavRight = true
        this.showNavRight = true

      } else {
        this.showNavRight = false
        // this.showNavRight = true
      }
    }

    // if (v1Param === 'true') {
    //   this.variants.firstVariant = true
    // } else if (v1Param === 'false') {
    //   this.variants.firstVariant = false
    // }

    // if (v2Param === 'true') {
    //   this.variants.secondVariant = true
    // } else if (v2Param === 'false') {
    //   this.variants.secondVariant = false
    // }

    // if (v3Param) this.variants.thirdVariant = true
    // else this.variants.thirdVariant = false

    // if (v4Param) this.variants.fourthVariant = true
    // else this.variants.fourthVariant = false

  
    chartEvents.$on('chartEvents')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    getCurrentChartSetting(val) {
      if(val?.id){
        this.updateSetting(val)
      }
    },
    variants() {
      if(this.variants?.sixthVariant){
        let dataRes = this.charts?.data?.offchart?.filter(item => item?.name != 'Volume')
        for (const data of dataRes) {
          this.charts.del(data?.name)
        }

        let updatedArr = this.getSelectedIndicators?.filter(item=>item.chartType == 'onchart')
        let res = [...updatedArr,...this.customIndicator]

        this.customIndicator?.map((item)=>{
                        this.addIndicator(item)
                      })
        // console.log("updatedArr",updatedArr)
        this.updateIndicatorList(res)
        
      }
    },
    selectedDate() {
      this.getRangeData()
    },
    getTheme(val) {
      this.isDark = val
    },
    getRemoveIndicatorName(val) {
      if(val){
        this.handleRemoveIndicator(val)
      }
    },
    
    getSelectedIndicators(indicators){
      localStorage.setItem("indicatorsList",JSON.stringify(indicators))
    }
    
  },
}
</script>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css');
.offBtn:hover {
  border: none;
  background-color: black;
  color: white;
  transition: all ease 0.5s;
}

.multi-chart {
  display: grid;
  width: 100%;
  grid-template-columns: 100% 100%;
  height: 90%;
}



.inner-container {
  border: 1px solid black;
  padding: 20px;
  text-align: center;
}

.abs {
  position: absolute;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.cla {
  z-index: 50;
  position: absolute;
  height: 120px;
  width: 120px;
  background-color: red;
  bottom: 0;
  left: 0;
}
</style>
