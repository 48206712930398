export const isSelected = {id: 1, name: "General", value: 'general', icon: 'generalImage', chartSettings:  [
    {id: 1, name: 'colorCandleUp', value: 'Candle Up Color', defaultValue: '#23a776', type: 'color'},
    {id: 2, name: 'colorCandleDw', value: 'Candle Down Color', defaultValue: '#e54150', type: 'color'},
    {id: 3, name: 'colorVolUp', value: 'Up Volume Colour', defaultValue: '#23a776', type: 'color'},
    {id: 4, name: 'colorVolDw', value: 'Down Volume Colour', defaultValue: '#e54150', type: 'color'},
    {id: 5, name: 'enableCrosshair', value: 'Enable CrossHair', defaultValue: true, type: 'checkbox'},
    // {id: 6, name: 'magnet', value: 'Magnet', defaultValue: false, type: 'checkbox'},
    {id: 7, name: 'candleBorder', value: 'Candle Border', defaultValue: true , type: 'checkbox'}
],}

export const allSetting = [
    {id: 1, name: "General", value: 'general', icon: 'generalImage', chartSettings: 
    [
        {id: 1, name: 'colorCandleUp', value: 'Candle Up Color', defaultValue: '#23a776', type: 'color'},
        {id: 2, name: 'colorCandleDw', value: 'Candle Down Color', defaultValue: '#e54150', type: 'color'},
        {id: 3, name: 'colorVolUp', value: 'Up Volume Colour', defaultValue: '#23a776', type: 'color'},
        {id: 4, name: 'colorVolDw', value: 'Down Volume Colour', defaultValue: '#e54150', type: 'color'},
        {id: 5, name: 'enableCrosshair', value: 'Enabled CrossHair', defaultValue: true, type: 'checkbox'},
        // {id: 6, name: 'magnet', value: 'Magnet', defaultValue: false, type: 'checkbox'},
        {id: 7, name: 'candleBorder', value: 'Candle Border', defaultValue: true , type: 'checkbox'}
    ]
,},
    {id: 2, name: "Variants", value: 'variants', icon: 'ThemeImage', chartSettings: 
    [
        {id: 8, name: 'Variant', value: 'Variant 1', defaultValue: false, type: 'radio',key:'firstVariant', variantList:{firstVariant:true,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:false}},
        {id: 9, name: 'Variant', value: 'Variant 2', defaultValue: false, type: 'radio',key:'secondVariant',variantList:{firstVariant:false,secondVariant:true,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:false}},
        {id: 10, name: 'Variant', value: 'Variant 3', defaultValue: false, type: 'radio',key:'thirdVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:true,fourthVariant:false,fifthVariant:false,sixthVariant:false}},
        {id: 11, name: 'Variant', value: 'Variant 4', defaultValue: false, type: 'radio',key:'fourthVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:true,fifthVariant:false,sixthVariant:false}},
        {id: 12, name: 'Variant', value: 'Variant 5', defaultValue: false, type: 'radio',key:'fifthVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:true,sixthVariant:false}},
        // {id: 13, name: 'Variant', value: 'Variant 6', defaultValue: false, type: 'radio',key:'sixthVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:true}},
    ]
,}
]

export const themeList = [
    {colors:{colorCandleUp:'#00B061',colorCandleDw:'#F23645',colorVolUp:'#4cc790',colorVolDw:'#fc6d72'}, id:1, name:'firstVariant', key:{firstVariant:true,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:false},label:'Theme 1'},
    {colors:{colorCandleUp:'#16B195',colorCandleDw:'#F23645',colorVolUp:'#16B195',colorVolDw:'#F23645'},id:2, name:'secondVariant',key:{firstVariant:false,secondVariant:true,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:false},label:'Theme 2'},
    {colors:{colorCandleUp:'#7D7E7E',colorCandleDw:'#e0e0e0',colorVolUp:'#7D7E7E',colorVolDw:'#D3D3D3'},id:3, name:'thirdVariant',key:{firstVariant:false,secondVariant:false,thirdVariant:true,fourthVariant:false,fifthVariant:false,sixthVariant:false},label:'Theme 3'},
    {colors:{colorCandleUp:'#0D66CE',colorCandleDw:'#F23645',colorVolUp:'#0D66CE',colorVolDw:'#F23645'},id:4, name:'fourthVariant',key:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:true,fifthVariant:false,sixthVariant:false},label:'Theme 4'},
    {colors:{colorCandleUp:'#23a776',colorCandleDw:'#e54150',colorVolUp:'#23a776',colorVolDw:'#e54150'},id:5, name:'fifthVariant',key:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:true,sixthVariant:false},label:'Theme 5'},
    // {colors:{colorCandleUp:'#008000',colorCandleDw:'#800000',colorVolUp:'#008000',colorVolDw:'#008000'},id:6, name:'sixthVariant',key:{firstVariant:false,secondVariant:fal  se,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:true},label:'Theme 6'},
    ]

export const variantColors = {
    firstVariant: {colorCandleUp:'#00B061',colorCandleDw:'#F23645',colorVolUp:'#4cc790',colorVolDw:'#fc6d72'},
    secondVariant: {colorCandleUp:'#16B195',colorCandleDw:'#F23645',colorVolUp:'#16B195',colorVolDw:'#F23645'},
    thirdVariant: {colorCandleUp:'#7D7E7E',colorCandleDw:'#e0e0e0',colorVolUp:'#7D7E7E',colorVolDw:'#D3D3D3'},
    fourthVariant: {colorCandleUp:'#0D66CE',colorCandleDw:'#F23645',colorVolUp:'#0D66CE',colorVolDw:'#F23645'},
    fifthVariant: {colorCandleUp:'#23a776',colorCandleDw:'#e54150',colorVolUp:'#23a776',colorVolDw:'#e54150'},
    // sixthVariant: {colorCandleUp:'#008000',colorCandleDw:'#800000',colorVolUp:'#008000',colorVolDw:'#008000'},
}

export const chartTypeObj = {
    WMA: "onchart",
    SMA:'onchart',
    TCI:'onchart',
    'Parabolic Stop and Reverse':'onchart',
    "Bollinger Bands": "onchart",
    "EMA": "onchart",
     "ALMA": "onchart",
     "HMA": "onchart",
     "KC": "onchart",
     "EMA": "onchart",
     "VWMA": "onchart",
     "SWMA": "onchart"
     
}

export  const dataTypes = [
    { type: "VolumePrimary", indices: [1],color:[] },
    { type: "Stoch", indices: [1, 2],color:['#3782f2', '#f48709'] },
    { type: "CovalData", indices: [1, 2],color:['#42b28a','#5691ce'] },
    { type: "COG", indices: [1],color:['#559de0'] },
    { type: "TSI", indices: [1,2],color:['#0eb1f7','#FA67A7'] },
    { type: "WilliamsR", indices: [1],color:['#0980E8'] },
    { type: "ROC", indices: [1],color:['#279fc4'] },
    { type: "ATR", indices: [1],color:['#e52468'] },
    { type: "MACD", indices: [1,2,3],color:['#3782f2','#f48709','#9954bb'] },
    { type: "RSI", indices: [1],color:['#ec206e'] },
    { type: "CCI", indices: [1],color:['#e79e5e'] },
    { type: "ATRp", indices: [1],color:['#f44336'] },
    { type: "BBW", indices: [1],color:['#4feff2'] },
    { type: "CMO", indices: [1],color:['#559de0'] },
    { type: "SAR", indices: [1],color:['#35a9c6'] },
    { type: "KCW", indices: [1],color:['#559de0'] },
    { type: "SWMA", indices: [1],color:['#EB946C'] },
    { type: "MFI", indices: [1],color:['#91c43c'] },
    { type: "MOM", indices: [1],color:['#C0C835'] },
    { type: "SMAX", indices: [1],color:['#000cff'],chartType: 'onchart' },
    { type: "VWMA", indices: [1],color:['#E85EA2'] },
    { type: "EMA", indices: [1],color:['#000cff'] },
    { type: "XMA", indices: [1],color:['#e78050'] },
    { type: "DMI", indices: [1,2,3],color:['#EF1360','#4087F3','#F8B567'] },
    { type: "BB", indices: [1,2,3],color:['#72D9DB','#72D9DB','#72D9DB'] },
];


export const keyName = {
    lineWidth:'Line Width',
    color:'Color',
    period:'Period',
    lineType:'Line Type'
}


export const copyIndicatorSettings = [
    {
        headingName: "Simple Moving Average (SMA)",
        settings: [
           {
            name:'',
            inputs:[
                {type: "color",defaultValue: '#FC0C0C'},
                {type: "number",defaultValue: 30},
            ]
           },
           {
            name:'',
            inputs:[
                {type: "color",defaultValue: '#110404'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    {
        headingName: "Simple Moving Average of OBV",
        settings: [
           {
            name:'',
            inputs:[
                {type: "color",defaultValue: '#008724'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    {
        headingName: "Simple Moving Average of OBV of Volume",
        settings: [
           {
            name:'',
            inputs:[
                {type: "color",defaultValue: '#9EA00E'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    {
        headingName: "Weighted Moving Average",
        settings: [
           {
            name:'Period',
            inputs:[
                {type: "color",defaultValue: '#9EA00E'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    {
        headingName: "Exponential Moving Average",
        settings: [
           {
            name:'Period',
            inputs:[
                {type: "color",defaultValue: '#1E31DB'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    ]

export const copyIndicatorSettingName = [
    {
  headingName: "Trend Channels",
  settings: [
    { name: 'Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 70 }] },
  ]
},
{
  headingName: "Bollinger Bands",
  settings: [{ name: 'Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 21 }] },
  { name: 'Deviations', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 40 }]}
]
},
{
  headingName: "Average True Range",
  settings: [
    { name: 'Period', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 34 }] },
    { name: 'Multiplier', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 34 }] }
]
},
{
  headingName: "MACD",
  settings: [{ name: 'Fast Period', inputs: [{ type: "color", defaultValue: '#1E31DB' }, { type: "number", defaultValue: 34 }] },
  { name: 'Slow Period', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 34 }] },
  { name: 'Signal Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 34 }] }
],
},
{
  headingName: "Rate Of Change",
  settings: [
    { name: 'Period', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 34 }] }
]
},
{
    headingName: "Relative Strength Index",
    settings: [
      { name: 'Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 34 }] }
  ]
  },
  {
    headingName: "Stochastic Oscillator",
    settings: [
      { name: 'Period', inputs: [{ type: "color", defaultValue: '#9EA00E' },{ type: "number", defaultValue: 15 }] },
      { name: '%K Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 5 }] },
      { name: '%D Period', inputs: [{ type: "color", defaultValue: '#9EA00E' },{ type: "number", defaultValue: 5 }] }
  ]
  },
  {
    headingName: "Williams %R",
    settings: [
      { name: 'Period', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 15 }] },
  ]
  },
  {
    headingName: "Parabolic SAR",
    settings: [
      { name: 'Start', inputs: [{ type: "number", defaultValue: 15 }] },
      { name: 'Increament', inputs: [{ type: "number", defaultValue: 5 }] },
      { name: 'Max', inputs: [{ type: "number", defaultValue: 5 }] },
      { name: 'Color', inputs: [{ type: "color", defaultValue: '#FC0C0C' }] }
  ]
  },
  {
    headingName: "VWAP",
    settings: [
      { name: 'Color', inputs: [{ type: "color", defaultValue: '#FC0C0C' }] }
  ]
  },
    ]

export const copyThemeSetting = [
    {
            heading:'Theme',
            key:'theme',
            settings:[
                {isGroup:[
                    {name:'Legacy Charts',type:'checkbox',defaultValue:true,key:'legacyChart'},
                    {name:'Yahoo Charts',type:'checkbox',defaultValue:false,key:'yahooChart'},
                    {name:'Standard Green/Red',type:'checkbox',defaultValue:false,key:'standardGR'},
                    {name:'Standard Blue/Red',type:'checkbox',defaultValue:false,key:'standardBR'},
                    {name:'Greyscale',type:'checkbox',defaultValue:false,key:'grayScale'}
                ]}
            ]
        },
        {
            heading:'Dark/Light Mode',
            key:'darkLightMode',
            settings:[
                {isGroup : [
                    {name:'System Preference',type:'checkbox',defaultValue:true,key:'system'},
                {name:'Dark Mode',type:'checkbox',defaultValue:false,key:'dark'},
                {name:'Light Mode',type:'checkbox',defaultValue:false,key:'light'},
                ]}
               
            ]
        },
        {
            heading:'Miscellaneous',
            key:'miscellaneous',
            settings:[
                {name:'Support & Resistance Lines',type:'checkbox',defaultValue:true,key:'supportLine'},
                {name:'Red & Green Arrows',type:'checkbox',defaultValue:true,key:'redGreenArrow'},
            ]
        },
    ]

export const copyChartSettingData = [
    {
        heading:'Line Chart',
        key:'lineChart',
        showToggle:false,
        isToggle:false,
        settings:[
            {name:'Line Color',type:'color',defaultValue:'#3853B4',key:'lineColor'}
        ]
    },
    {
        heading:'Up/Down Bar Chart Color',
        showToggle:true,
        key:'upDownBarChart',
        isToggle:true,
        settings:[
            {name:'Bar Chart Color',type:'color',key:'barChartColor',defaultValue:'#3853B4'},
            {
            isGroup:[ 
             {name:'Up Color',type:'color',defaultValue:'#41901C',key:'barUpColor'},
            {name:'Down Color',type:'color',defaultValue:'#E51616',key:'barDownColor'},
            ]
            }
           
        ]
    },
    {
        heading:'Up/Down Volume Color',
        showToggle:true,
        isToggle:true,
        key:'upDownVolume',
        settings:[
            {name:'Volume Color',type:'color',key:'volumeColor',defaultValue:'#3853B4'},
            {
            isGroup:[ 
                {name:'Up Volume Color',type:'color',key:'upVolumeColor',defaultValue:'#41901C'},
                {name:'Down Volume Color',type:'color',key:'downVolumeColor',defaultValue:'#E51616'},
            ]
            },
            {name:'On-Balance Volume Color',type:'color',key:'onBalanceVolume',defaultValue:'#3853B4'},
        ]
    },
    {
        heading:'Cross Hair',
        key:'crossHair',
        showToggle:true,
        isToggle:true,
        settings:[
            {name:'Line Color',type:'color',key:'crossLineColor',defaultValue:'#3853B4'},
            {name:'Magnet',type:'checkbox',key:'magnet',defaultValue:true},
        ]
    },
    {
        heading:'Hover Y-Axis',
        key:'yAxis',
        showToggle:false,
        isToggle:false,
        settings:[
            {isGroup:[
                {name:'Box Color',type:'color',key:'boxColor',defaultValue:'#3853B4'},
                {name:'Text Color',type:'color',key:'boxTextColor',defaultValue:'#3853B4'},
            ]}
         
        ]
    },
]